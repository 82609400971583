<template>
  <div class="container-fluid" id="main">
    <header class="w-100"></header>
    <div v-html="html"></div>
    <footer class="navbar fixed-bottom">
      <button @click="goback" class="btn btn-warning w-100">{{i18n.$t('PrePage')}}</button>
    </footer>
  </div>
</template>
<script>
import { ref  } from 'vue'
import { useI18n } from "@/i18nPlugin";
import { store } from "@/store";
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router'
export default {
  setup() {
    const route = useRoute();
    const html = ref(route.params.html);
    const router = useRouter()
    const goback = () => {
      router.go(-1);
    }
    const i18n = useI18n();
    const setLang = (value) => {
      store.commit('setLang', value);
      localStorage.setItem('setLang', value);
      i18n.locale.value = value
    }
    return { html, goback, i18n, setLang };
  }
};
</script>
<style lang="scss">
.container-fluid {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
#main {
  color: #000;
  width: 100%;
  height: auto;
  background-color: #fff;
}
header {
  height: 100px;
  background-color: rgb(255, 241, 0);
}
footer {
  background-color: rgb(255, 241, 0);
}
</style>