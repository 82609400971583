<template>
  <div id="app">
    <router-link to="/"></router-link>
    <router-view />
  </div>
</template>
<script>
import { provideI18n } from "@/i18nPlugin";
import tw from '@/i18n/tw'
import en from '@/i18n/en'
import jp from '@/i18n/jp'
import { store } from "@/store";
export default {
  setup() {
      //let locale = 'jp';
      let locale = navigator.language?.split('-')[1]?.toLowerCase() || 'jp';
      //console.log("LOCALE: ",locale)
      localStorage.setItem('setLang', locale);
      if (localStorage.getItem('setLang')) {
        locale = localStorage.getItem('setLang');
        store.commit('setLang', locale);
      } else {
        store.commit('setLang', locale);
      }
      provideI18n({
        locale: locale,
        messages: {
          'tw': tw,
          'en': en,
          'jp': jp,
        }
      })
    } 
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
