<template>
  <div class="container-fluid" id="bg">
    <img :src="bg" />
    <div class="card w-100" id="card">
      <div class="row">
        <div class="col offset-md-3">
          <img src="../assets/camara.png" @click="camara" />
        </div>
        <div class="col offset-md-3">
          <img src="../assets/change.png" @click="goback" />
        </div>
      </div>
      <div class="card-body">
        <label for="serial">{{ i18n.$t('SerialIn')}}</label>
        <div class="card-body">
          <input type="text" id="serial" v-model="serial" />
          <button class="btn btn-danger" @click="go">{{ i18n.$t('Confirm')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { ref , onMounted } from 'vue'
import { useRouter } from 'vue-router';
import { useI18n } from "@/i18nPlugin";
import { store } from "@/store";
const api = `${process.env.VUE_APP_apiUrl}/api.php/`;
export default {
  setup() {
    const router = useRouter()
    const project = ref(''); 
    const serial = ref(''); 
    const bg = ref(''); 
    const i18n = useI18n();
    const setLang = (value) => {
        store.commit('setLang', value);
        localStorage.setItem('setLang', value);
        i18n.locale.value = value
      }
    onMounted(() => {
      project.value = window.localStorage.getItem("project");
      bg.value = 
      project.value === ""
        ? require("../assets/1122home.jpg")
        : `http://mycard.bz/api/images/${project.value}.jpg`; 
    })
    const camara = () => {
      router.push("/Qrcode");
    }
    const goback = () => {
      router.push("/");
    }
    const go = async() => {
      if (serial.value === "") {
        router.push({
          name: "ErrPage",
          query: { message: i18n.$t('SerialIn') },
        });
        return;
      }
      var retval = await axios
        .get(api + project.value + "/" + serial.value, {})
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          console.log(err);
          return null;
        });
      router.push({
        name: "Result",
        params: { html: retval },
      });
    }

    return {project, serial, bg, i18n, setLang, onMounted, camara, goback, go}
  }
};
</script>
<style lang="scss" scoped>
.container-fluid {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
);

.container-fluid img {
  width: 100%;
  height: auto;
}
#bg {
  padding: 0px;
  margin: 0px;
}
#card {
  position: absolute;
  top: 150px;
  z-index: auto;
}
#card img {
  width: 35%;
  height: auto;
}
.card-body {
  color: #990000;
}
#card #serial {
  margin: 3px;
  width: 150px;
}
</style>