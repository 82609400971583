import { createStore } from "vuex";

export const store = createStore({
  state: {
    project: "",
    lang: null  // 存放使用者選用的語系
  },
  mutations: {
    // 切換語系設定
    setLang(state, value) {
      state.lang = value;
    }
  },
  actions: {},
  modules: {}
});