import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Qrcode from '../views/Qrcode.vue'
import Result from '../views/Result.vue'
const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home,
    props: true
  },
  {
    path: '/Result/:html',
    name: 'Result',
    component:Result,
    props: true
  },
  {
    path: '/Qrcode',
    name: 'Qrcode',
    component:Qrcode,
  },
  {
    path: '/errpage/:message',
    name: 'ErrPage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "errpage" */ '../views/ErrPage.vue'),
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router;