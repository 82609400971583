<template>
  <div class="container-fluid" id="bg">
    <img src="../assets/1122home.jpg" />

    <div class="card w-100" id="card">
      <label for="project">{{ i18n.$t('ProjectIn')}}</label>
      <div class="card-body">
        <input type="text" id="project" v-model="project" />
        <button class="btn btn-danger" @click="run">{{ i18n.$t('Confirm')}}</button>
      </div>
    </div>
  </div>
</template>
<script>
import { ref , onMounted } from 'vue'
import axios from "axios";
import moment from "moment";
import { useI18n } from "@/i18nPlugin";
import { store } from "@/store";
import { useRouter } from 'vue-router';
//axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
const api = `${process.env.VUE_APP_apiUrl}/checkProject.php/`;

export default {
  setup() {
      const router = useRouter()
      const project = ref(''); 
      const i18n = useI18n();
      const setLang = (value) => {
        store.commit('setLang', value);
        localStorage.setItem('setLang', value);
        i18n.locale.value = value
      }
      const run = async() => {
        if (project.value === "") {
          router.push({
            name: "ErrPage",
            query: { message: i18n.$t('ProjectIn') },
          });
          return;
        }
        var retval = await axios
          .get(api + project.value, {
            mode: "cors",
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Headers": "*",
              "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, DELETE",
            },
          })
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            console.debug(err);
          });
          let today = moment().format("YYYY-MM-DD");

          if (retval !== null && today >= retval?.start && today <= retval?.end) {
            window.localStorage.setItem("project", project.value);
            router.push("/Home");
          } else
            router.push({
              name: "ErrPage",
              params: { message: i18n.$t('UnProject') },
            });
      }
      onMounted(() => {
        window.localStorage.removeItem("project");
      })
      return { project, onMounted, i18n, setLang, run };
    }
};
</script>

<style lang="scss">
.container-fluid {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
}

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
);

#bg {
  padding: 0px;
  margin: 0px;
}
@media only screen and (-Webkit-min-device-pixel-ratio: 2),
only screen and (-moz-min-device-pixel-ratio: 2),
only screen and (-o-min-device-pixel-ratio: 2),
only screen and (min-device-pixel-ratio: 2) {
.container-fluid img {
  width: 100%;
  height: auto;
}
}

#card label {
  color: #990000;
}

#card {
  position: relative;
  top: 0px;
  z-index: auto;
  background-color: rgb(255, 241, 0);
  border: 0;
}

#card #project {
  margin: 3px;
  width: 150px;
}
</style>
