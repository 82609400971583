<template>
  <div>
  <qrcode-stream @decode="onDecode" @init="onInit" v-if="!destroyed">
    <div class="loading-indicator" v-if="loading">Loading...</div>
    <footer class="navbar fixed-bottom">
      <button @click="goback" class="btn btn-warning w-100">{{i18n.$t('PrePage')}}</button>
    </footer>
  </qrcode-stream> 
  </div>
</template>
<script>
import { ref, onMounted  } from 'vue';
import { useI18n } from "@/i18nPlugin";
import { store } from "@/store";
import axios from "axios";
import { QrcodeStream } from 'vue3-qrcode-reader'
import { useRouter } from 'vue-router';
const api = `${process.env.VUE_APP_apiUrl}/api.php/`;
export default {
  setup() {
    const router = useRouter();
    const loading = ref(false);
    const destroyed = ref(false);
    const project = ref('');

    onMounted(() => {
        project.value = window.localStorage.getItem("project");
      })

    const onDecode = async(content) => {
      console.log(api + project.value + "/" + content)
      var retval = await axios
        .get(api + project.value + "/" + content, {})
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          console.log(err);
          return null;
        });
      router.push({
        name: "Result",
        params: { html: retval },
      });
    };

    const onInit = async(promise) => {
      loading.value = true;
      try {
        await promise;
      } catch (error) {
        console.error(error);
      } finally {
        loading.value = false;
      }
    };

    const reload = async() => {
      destroyed.value = true;
      await this.$nextTick();
      destroyed.value = false;
    };

    const goback = () => {
      router.go(-1);
    };  

    const i18n = useI18n();
    const setLang = (value) => {
      store.commit('setLang', value);
      localStorage.setItem('setLang', value);
      i18n.locale.value = value
    }

    return {loading, destroyed, project, i18n, onMounted, onDecode, onInit, reload, goback, setLang}
  },
  components: {
    QrcodeStream,
  }
};
</script>
<style lang="scss" scoped>
.loading-indicator {
  font-weight: bold;
  font-size: 2rem;
  text-align: center;
}
footer {
  background-color: rgb(255, 241, 0);
}
</style>